import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import styles from "../../Styles/order.module.css";
import ClientPanel from "./ClientPanel";
import PaymentPanel from "./PaymentPanel";
import ProductPanel from "./ProductPanel";
import TotalPanel from "./TotalPanel";
import {
  NoteModel,
  OrderLineModel,
  OrderModel,
  OrderValidationSchema,
  ProductModel,
} from "../../Utils/Models";
import { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getData } from "../../Services/getData";
import { endpoints, localUrlEnum } from "../../Utils/UrlEnum";
import Config from "../../Utils/Config";
import { Vocabulary } from "../../Utils/Vocabulary";
import { checkIfObjectIsEmpty, formatStringDate } from "../../Utils/Utils";
import { postData } from "../../Services/postData";
import ShippingPanel from "./ShippingPanel";
import { updateData } from "../../Services/updateData";
import StatusPanel from "./StatusPanel";
import StepperNotes from "../Widgets/StepperNotes";
import TransportMethods from "./TransportMethods";
import CustomPanel from "../CustomPanel";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InvoicePanel from "./InvoicePanel";
import NoDataFound from "../NoDataFound";
import { AxiosResponse } from "axios";
import { FormsIds } from "../../Utils/Constants";
import DirtyFormGuard from "../Widgets/DirtyFormGuard";
import useCustomMemo from "../../Hooks/useCustomMemo";

export default function Order() {
  const [products, setProducts] = useState([] as ProductModel[]);
  const { id } = useParams();
  const ref: any = useRef();
  const {
    register,
    control,
    handleSubmit,
    getValues,
    formState,
    reset,
    setValue,
    watch,
    clearErrors,
  } = useForm({
    resolver: yupResolver(OrderValidationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: false,
    criteriaMode: "firstError",
  });
  const navigate = useNavigate();
  const lineItems = watch("line_items");
  const shouldGenerateInvoice = getValues("_id") ? true : false;
  const [isSaved, setIsSaved] = useState(false);
  const memo = useCustomMemo();
  const managements = memo?.managements || [];

  /**
   *
   */
  useEffect(() => {
    setIsSaved(false);
    getProducts();
    if (id) getOrder(id);
    if (!id && (!lineItems || lineItems?.length === 0)) addNewProductForList();
  }, []);

  /**
   *
   */
  useEffect(() => {
    const performAction = () => {
      const data: any = getValues();
      //check if the order has been modified
      updateOrder(data);
    };
    // Set up the interval to execute the action every 10 minutes
    const intervalId = setInterval(performAction, Config.autoUpdateInterval);
    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  /**
   *
   * @param search
   */
  const getProducts = () => {
    getData(`${endpoints.getProductsSearch}`).then((res: AxiosResponse) => {
      if (!res?.data) return;
      setProducts(res?.data);
    });
  };

  /**
   *
   * @param id
   */
  const getOrder = (id: string) => {
    getData(`${endpoints.ordersEndpoint}/${id}`).then((res: AxiosResponse) => {
      if (!res || !res?.data) return;
      reset(res.data, { keepDefaultValues: false });
    });
  };

  /**
   *
   * @param st
   */
  function handleChange(st?: any) {
    setValue("status", st, {
      shouldDirty: true,
    });
  }

  /**
   *
   * @param order
   */
  const resetOrderData = (order: any) => {
    reset(order, { keepDefaultValues: false });
  };

  /**
   *
   * @param data
   */
  const saveOrder = (data: OrderModel) => {
    const orderId = getValues("_id");
    if (orderId) {
      updateOrder(data);
    } else {
      setIsSaved(true);
      postData(endpoints.ordersEndpoint, data).then((res: AxiosResponse) => {
        if (res?.data) {
          navigate(`${localUrlEnum.order}/${res?.data?._id}`);
          setValue("createdAt", res?.data?.createdAt);
          setValue("updatedAt", res?.data?.updatedAt);
          setValue("order_notes", res?.data?.order_notes);
          setValue("_id", res?.data?._id);
          setValue("status", res?.data?.status);
        }
      });
    }
  };

  /**
   *
   * @param data
   */
  const updateOrder = (data: any) => {
    const orderId = getValues("_id");
    const url = `${endpoints.ordersEndpoint}/${orderId}`;
    // if (Object.keys(formState.dirtyFields).length !== 0)
    updateData(url, data).then((res: AxiosResponse) => {
      if (res?.data) {
        reset(res.data, {
          keepValues: false,
          keepDirty: false,
          keepDefaultValues: false,
        });
      }
    });
  };

  /**
   *
   * @param id
   */
  const addNewProductForList = () => {
    const newOrderLine: any = new OrderLineModel();
    const currentLineItems = getValues("line_items");
    if (currentLineItems) {
      currentLineItems?.push(newOrderLine);
      setValue("line_items", currentLineItems);
    } else {
      setValue("line_items", [newOrderLine]);
    }
  };

  /**
   *
   * @param notes
   */
  const updateOrderNotes = (notes: NoteModel[]) => {
    const orderId = getValues("_id");
    if (orderId)
      postData(`${endpoints.updateOrderNotes}/${orderId}`, notes).then(
        (res: AxiosResponse) => {
          if (res?.data) {
            reset(res?.data);
          }
        }
      );
  };

  /**
   *
   * @param onChange
   * @param value
   * @returns
   */
  const selectAllLineItemsForInvoice = (onChange: any, value: boolean) => {
    //iterate over line_items and if hasBeenInvoiced is false, set lineChecked to true
    const lineItems = getValues("line_items");
    if (!lineItems) return;
    for (let i = 0; i < lineItems.length; i++) {
      if (!lineItems[i].hasBeenInvoiced && lineItems[i].name !== "") {
        lineItems[i].lineChecked = value;
      }
    }
    setValue("line_items", lineItems);
    onChange(value);
  };

  /**
   *
   * @param model
   */
  const onSubmit = (model: any) => {
    model = model ? model : getValues();
    saveOrder(model);
  };
  /**
   *
   * @param model
   */
  const onError = (model: any) => {
    // console.log(model);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit, onError)}
      method="get"
      ref={ref}
      id={FormsIds.orderForm}
      className={styles.mainContainer}
    >
      <DirtyFormGuard
        when={
          formState.isDirty &&
          Object.keys(formState.dirtyFields).length > 0 &&
          !isSaved
        }
      />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <div className={styles.spaceBetween}>
            <div className={styles.spaceBetween}>
              <Typography variant="h4">
                {`${Vocabulary.order}: ${
                  getValues("number") || Vocabulary.new
                }`}
              </Typography>
            </div>
            <div className={styles.spaceBetween}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: 20,
                  rowGap: 0,
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: 300 }}>
                  {`${Vocabulary.creationDate}: ${
                    formatStringDate(
                      getValues("date_created") || getValues("createdAt"),
                      Config.momentEUDateTimeFormat
                    ) || Vocabulary.never
                  }`}
                </Typography>
                <Typography
                  variant="h6"
                  style={{ marginTop: -10, fontWeight: 300 }}
                >
                  {`${Vocabulary.lastModified}: ${
                    formatStringDate(
                      watch("date_modified") || getValues("updatedAt"),
                      Config.momentEUDateTimeFormat
                    ) || Vocabulary.never
                  }`}
                </Typography>
              </div>
              <Button
                size="large"
                type="submit"
                id={FormsIds.orderForm}
                variant="contained"
              >
                {Vocabulary.save}
              </Button>
            </div>
          </div>
        </Grid>
        {/**STATUS */}
        <Grid item xs={12} sm={12} md={12}>
          <StatusPanel
            selectedOption={watch("status")}
            handleChange={handleChange}
            allOptions={memo.storage?.orderStatuses}
          ></StatusPanel>
        </Grid>
        {/**CLIENTI */}
        <div className={styles.containers}>
          <Grid item xs={12} sm={12} md={12} className={styles.grid}>
            <CustomPanel title={Vocabulary.clientDetails}>
              <ClientPanel
                register={register}
                control={control}
                setValue={setValue}
                getValues={getValues}
                watch={watch}
                clearErrors={clearErrors}
                formState={formState}
              />
            </CustomPanel>
          </Grid>
          {/**MODALITATI TRANSPORT */}
          <Grid className={styles.grid} item xs={12} sm={12} md={12}>
            <CustomPanel
              title={Vocabulary.transportMethods}
              error={
                getValues("shipping_method") !== null &&
                getValues("shipping_method") !== undefined &&
                !checkIfObjectIsEmpty(getValues("shipping_method"))
                  ? null
                  : Vocabulary.requiredField
              }
            >
              <TransportMethods
                getValues={getValues}
                setValue={setValue}
                error={
                  getValues("shipping_method") === null ||
                  getValues("shipping_method") === undefined ||
                  checkIfObjectIsEmpty(getValues("shipping_method"))
                    ? true
                    : false
                }
                control={control}
                transportOptions={memo?.storage?.transportOptions}
              />
            </CustomPanel>
          </Grid>
          {/**INFORMATII TRANSPORT */}
          <Grid className={styles.grid} item xs={12} sm={12} md={12}>
            <CustomPanel title={Vocabulary.shippingInfo}>
              <ShippingPanel
                register={register}
                reset={reset}
                getValues={getValues}
                setValue={setValue}
                watch={watch}
                formState={formState}
              />
            </CustomPanel>
          </Grid>

          {/**NOTITE */}
          <Grid item xs={12} sm={12} md={12} className={styles.grid}>
            <CustomPanel title={Vocabulary.note}>
              <StepperNotes
                updateNotes={updateOrderNotes}
                notes={getValues("order_notes") || []}
                disabled={!getValues("_id") ? true : false}
              />
            </CustomPanel>
          </Grid>
        </div>
        <div className={styles.containers}>
          {/**PANOU PRODUSE */}
          <Grid item xs={12} sm={12} md={12} className={styles.grid}>
            <CustomPanel title={Vocabulary.orderProducts}>
              <Fragment>
                {shouldGenerateInvoice ? (
                  <FormControlLabel
                    className={styles.fromControlLabel}
                    control={
                      <Controller
                        name={"invoiceAllProducts"}
                        control={control}
                        defaultValue={false}
                        render={({ field: props }) => (
                          <Checkbox
                            {...props}
                            checked={props.value}
                            onChange={(e) => {
                              selectAllLineItemsForInvoice(
                                props.onChange,
                                e.target.checked
                              );
                            }}
                          />
                        )}
                      />
                    }
                    label={Vocabulary.selectAllProductsForInvoice}
                  />
                ) : null}
                {lineItems?.map((product: any, index: any) => (
                  <div style={{ marginBottom: 10 }} key={index}>
                    <ProductPanel
                      invoiceGeneration={shouldGenerateInvoice}
                      key={index}
                      index={index}
                      product={product}
                      register={register}
                      reset={reset}
                      getValues={getValues}
                      setValue={setValue}
                      watch={watch}
                      control={control}
                      products={products}
                      managements={managements || []}
                    />
                  </div>
                ))}

                <Button
                  onClick={(e: any) => addNewProductForList()}
                  variant="contained"
                >
                  {Vocabulary.addProducts}
                </Button>
              </Fragment>
            </CustomPanel>
          </Grid>

          {/**NOTA CLIENT */}
          <Grid className={styles.grid} item xs={12} sm={12} md={12}>
            <CustomPanel title={Vocabulary.clientNote}>
              <div className={styles.customerNotePanel}>
                <Typography sx={{ padding: "10px 5px !important" }}>
                  {getValues("customer_note") ? (
                    getValues("customer_note")
                  ) : (
                    <NoDataFound />
                  )}
                </Typography>
              </div>
            </CustomPanel>
          </Grid>

          {/**INFORMATII PLATA */}
          <Grid className={styles.grid} item xs={12} sm={12} md={12}>
            <CustomPanel
              title={Vocabulary.paymentMethods}
              error={
                getValues("payment_method_title") !== null &&
                getValues("payment_method_title") !== undefined
                  ? null
                  : Vocabulary.requiredField
              }
            >
              <PaymentPanel
                getValues={getValues}
                setValue={setValue}
                control={control}
                watch={watch}
                error={getValues("payment_method_title") ? false : true}
                paymentMethods={
                  memo?.storage?.orderSettings?.value?.paymentMethods
                }
              />
            </CustomPanel>
          </Grid>
          {/** INFORMATII FACTURARE */}
          <Grid className={styles.grid} item xs={12} sm={12} md={12}>
            <CustomPanel title={Vocabulary.invoicesInfo}>
              <InvoicePanel
                handleSaveOrder={resetOrderData}
                getOrderValues={getValues}
                watchOrder={watch}
                managements={managements}
              />
            </CustomPanel>
          </Grid>

          {/**TOTALURI */}
          <Grid item xs={12} sm={12} md={12} className={styles.grid}>
            <CustomPanel title={Vocabulary.total}>
              <TotalPanel
                register={register}
                reset={reset}
                getValues={getValues}
                setValue={setValue}
                watch={watch}
              />
            </CustomPanel>
          </Grid>
        </div>
      </Grid>
    </form>
  );
}
