import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { useContext, useEffect, useRef, useState } from "react";
import { CRUDContext } from "./ClientsTable";
import { getData } from "../../Services/getData";
import { AxiosResponse } from "axios";
import { endpoints } from "../../Utils/UrlEnum";
import SpecialDivider from "../SpecialDivider";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { updateData } from "../../Services/updateData";
import { postData } from "../../Services/postData";
import Periodicity from "./Periodicity";
import { Add, Delete, Search } from "@mui/icons-material";
import { ClientFormProps } from "../../Utils/Types";
import { FormsIds } from "../../Utils/Constants";
import Config from "../../Utils/Config";
import styles from "../../Styles/clientPanel.module.css";
import { WebSocketContext } from "../../Context/WebSocketContext";
import { ClientValidationSchema } from "../../Utils/Models";

export default function ClientForm(props: ClientFormProps) {
  const clientContext: any = useContext(CRUDContext);
  const ref: any = useRef();
  const firstUpdate = useRef(true);
  const websocketContext = useContext(WebSocketContext);
  const [disableSearchButton, setDisabledSearchButton] = useState(false);
  const [clientCategory, setClientCategory] = useState([]);
  const {
    register,
    control,
    handleSubmit,
    getValues,
    formState,
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      phone: [""],
      periodicity: [
        {
          name: "",
          nextContactDate: undefined,
          nextTransportDate: undefined,
          noDays: Config.standardNewContactNoDays,
          day: {
            name: "",
            value: 0,
          },
        },
      ],
    },
    resolver: yupResolver(ClientValidationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: false,
    criteriaMode: "firstError",
  });
  const periodicityLength = getValues("periodicity")?.length;

  /**
   *
   */
  useEffect(() => {
    getData(endpoints.getClientCategory).then((res: AxiosResponse) => {
      setClientCategory(res.data);
    });
  }, []);

  /**
   *
   */
  useEffect(() => {
    if (clientContext?._id && clientContext?._id !== "") reset(clientContext);
  }, []);

  /**
   *
   */
  useEffect(() => {
    if (props.selectedClient?.name && props.selectedClient?.name !== "") {
      reset(props.selectedClient);
    }
  }, []);

  /**
   *
   */
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (!firstUpdate.current) ref.current.submit();
  }, []);

  /**
   *
   */
  const addPhoneNumber = () => {
    //get the current value of the phone array
    const phone = getValues("phone") || [];
    //push a new empty string to the array
    phone.push("");
    //update the value of the phone array
    setValue("phone", phone);
  };

  /**
   *
   * @param index
   */
  const deletePhoneNumber = (index: number) => {
    //get the current value of the phone array
    const phone = getValues("phone") || [];
    //remove the element from the array
    phone.splice(index, 1);
    //update the value of the phone array
    setValue("phone", phone);
  };

  /**
   *
   * @param id
   */
  async function updateElement(model: any) {
    const url = `${endpoints.clientsEndpoint}/${model?._id}`;
    updateData(url, model).then((res: AxiosResponse) => {
      if (res && res?.data) {
        websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
        if (props.onSuccess) props.onSuccess(null, res?.data);
        props.onClose();
      }
    });
  }

  /**
   *
   * @param id
   */
  async function createElement(model: any) {
    const url = `${endpoints.clientsEndpoint}`;
    const res = (await postData(url, model)) as any;
    if (!res || res?.error) {
      return;
    }
    websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
    if (props.onSuccess) props.onSuccess(null, res.data);
    props.onClose();
  }

  /**
   *
   * @param model
   */
  const onSubmit = (model: any) => {
    model._id ? updateElement(model) : createElement(model);
  };

  /**
   *
   * @param model
   */
  const onError = (model: any) => {
    // console.log(model);
  };

  /**
   *
   */
  const autocompleteWithCompanyData = async () => {
    let fiscalCode: any = getValues("fiscalCode");
    if (!fiscalCode) return;
    if (
      fiscalCode &&
      typeof fiscalCode === "string" &&
      fiscalCode.includes("RO")
    ) {
      fiscalCode = fiscalCode.replace("RO", " ");
    }
    getData(`${endpoints.getANAFDataAboutClient}/${fiscalCode}`).then(
      (res: AxiosResponse) => {
        if (res && res?.data) {
          setValue("name", res?.data?.name);
          setValue("tradeRegisterNo", res?.data?.tradeRegisterNo);
          setValue("iban", res?.data?.iban);
          setValue("fiscalCode", res?.data?.fiscalCode);
          setValue("address", res?.data?.address);
        }
        setDisabledSearchButton(false);
      }
    );
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit(onSubmit, onError)(e);
      }}
      method="get"
      ref={ref}
      id={FormsIds.clients}
      className={styles.clientFormContainer}
    >
      <Grid container spacing={Config.standardGridSpacing}>
        <Grid item xs={12} sm={12} md={12}>
          <SpecialDivider title={Vocabulary.userSettings} />
        </Grid>
        <Grid xs={12} sm={12} md={4} item>
          <Typography>{Vocabulary.shouldClientReceiveEmail}</Typography>
          <Controller
            name={`receiveEmails`}
            control={control}
            defaultValue={true}
            render={({ field: { value, onChange, ref } }) => (
              <Switch onChange={onChange} checked={value} inputRef={ref} />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <SpecialDivider title={Vocabulary.details} />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <TextField
            {...register("name")}
            id="name"
            name="name"
            label={Vocabulary.name}
            fullWidth
            error={typeof formState.errors.name === "object"}
            helperText={formState.errors.name?.message?.toString()}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            {...register("email")}
            variant="outlined"
            name="email"
            id="email"
            label={Vocabulary.email}
            fullWidth
            error={typeof formState.errors.email === "object"}
            helperText={formState.errors.email?.message?.toString()}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Controller
            name="isLegalPerson"
            control={control}
            render={({ field: props }) => {
              return (
                <FormControl
                  error={typeof formState.errors.isLegalPerson === "object"}
                  component="fieldset"
                  variant="standard"
                >
                  <FormLabel component="legend">
                    {Vocabulary.clientType}
                  </FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      className={
                        typeof formState.errors.isLegalPerson === "object"
                          ? styles.checkboxTextError
                          : ""
                      }
                      control={
                        <Checkbox
                          className={
                            typeof formState.errors.isLegalPerson === "object"
                              ? styles.checkboxTextError
                              : ""
                          }
                          checked={watch(`isLegalPerson`) === true}
                          onChange={(e) => props.onChange(e.target.checked)}
                          name="legalPerson"
                        />
                      }
                      label={Vocabulary.legalPerson}
                    />
                    <FormControlLabel
                      className={
                        typeof formState.errors.isLegalPerson === "object"
                          ? styles.checkboxTextError
                          : ""
                      }
                      control={
                        <Checkbox
                          className={
                            typeof formState.errors.isLegalPerson === "object"
                              ? styles.checkboxTextError
                              : ""
                          }
                          checked={watch(`isLegalPerson`) === false}
                          onChange={(e) => props.onChange(false)}
                          name="physicalPerson"
                        />
                      }
                      label={Vocabulary.physicalPerson}
                    />
                  </FormGroup>
                  <FormHelperText>
                    {formState.errors.isLegalPerson?.message?.toString()}
                  </FormHelperText>
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          {watch("isLegalPerson") === true ? (
            <TextField
              {...register("fiscalCode")}
              name="fiscalCode"
              id="fiscalCode"
              value={watch("fiscalCode") || ""}
              label={Vocabulary.fiscalCode}
              fullWidth
              variant="outlined"
              error={typeof formState.errors.fiscalCode === "object"}
              helperText={formState.errors.fiscalCode?.message?.toString()}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={disableSearchButton}
                      onClick={() => {
                        setDisabledSearchButton(true);
                        autocompleteWithCompanyData();
                      }}
                    >
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          ) : watch("isLegalPerson") === false ? (
            <TextField
              {...register("CNP")}
              name="CNP"
              id="CNP"
              label={Vocabulary.CNP}
              value={watch("CNP") || ""}
              fullWidth
              type="number"
              variant="outlined"
              error={typeof formState.errors.CNP === "object"}
              helperText={formState.errors.CNP?.message?.toString()}
              InputLabelProps={{
                shrink: true,
              }}
            />
          ) : null}
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            {...register("tradeRegisterNo")}
            name="tradeRegisterNo"
            id="tradeRegisterNo"
            label={Vocabulary.tradeRegisterNo}
            fullWidth
            variant="outlined"
            error={typeof formState.errors.tradeRegisterNo === "object"}
            helperText={formState.errors.tradeRegisterNo?.message?.toString()}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Controller
            name="category"
            control={control}
            defaultValue={[]}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <Autocomplete
                freeSolo={false}
                disableClearable
                multiple
                disablePortal
                onChange={(event, value) => field.onChange(value)}
                getOptionDisabled={(option: any) =>
                  !!getValues("category")?.find(
                    (element: any) => element?._id === option?._id
                  )
                }
                value={getValues("category") || []}
                isOptionEqualToValue={(option, value) => option?._id === value}
                getOptionLabel={(option: any) => option.name}
                id="category"
                options={clientCategory}
                renderInput={(params) => (
                  <TextField
                    error={!!error}
                    helperText={error?.message}
                    label={Vocabulary.category}
                    name="category"
                    type="search"
                    {...params}
                  />
                )}
              />
            )}
          />
        </Grid>
        {/* ///////////////////////////////////////////////////////////////////////////NR. DE TELEFON */}
        <Grid item xs={12} sm={12} md={12}>
          <SpecialDivider
            title={Vocabulary.phoneNumbers}
            button={
              <Button
                variant="outlined"
                startIcon={<Add />}
                onClick={addPhoneNumber}
              >
                {Vocabulary.addPhoneNumber}
              </Button>
            }
          />
        </Grid>
        {watch("phone")?.map((phone: any, index: number) => (
          <Grid item xs={12} sm={12} md={4} key={index}>
            <Controller
              name={`phone.${index}`}
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <TextField
                  variant="outlined"
                  name={`phone[${index}]`}
                  id={`phone[${index}]`}
                  label={`${Vocabulary.phone} #${index + 1}`}
                  fullWidth
                  value={value || ""}
                  onChange={onChange}
                  error={typeof formState.errors.phone?.[index] === "object"}
                  helperText={formState.errors.phone?.[
                    index
                  ]?.message?.toString()}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton onClick={() => deletePhoneNumber(index)}>
                          <Delete />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  ref={ref}
                />
              )}
            />
          </Grid>
        ))}

        {/* ///////////////////////////////////////////////////////////////////////////PERIODICITATE */}
        <Grid item xs={12} sm={12} md={12}>
          <SpecialDivider title={Vocabulary.periodicity} />
        </Grid>

        <Periodicity
          periodicityLength={periodicityLength}
          control={control}
          getValues={getValues}
          setValue={setValue}
          client={clientContext}
        />

        {/* ///////////////////////////////////////////////////////////////////////////ADRESA */}

        <Grid item xs={12} sm={12} md={12}>
          <SpecialDivider title={Vocabulary.address} />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            {...register(`address.city`)}
            name="address.city"
            id="city"
            label={Vocabulary.city}
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <TextField
            {...register(`address.county`)}
            name="address.county"
            id="county"
            label={Vocabulary.county}
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <TextField
            {...register(`address.country`)}
            name="address.country"
            id="country"
            label={Vocabulary.country}
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            {...register(`address.postcode`)}
            name="address.postcode"
            id="postcode"
            label={Vocabulary.postcode}
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={8}>
          <TextField
            {...register(`address.details`)}
            name="address.details"
            id="details"
            label={Vocabulary.addressDetails}
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        {/* ///////////////////////////////////////////////////////////////////////////ALTE INFORMATII */}

        <Grid item xs={12} sm={12} md={12}>
          <SpecialDivider title={Vocabulary.otherInfos} />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <TextField
            {...register("bank")}
            name="bank"
            id="bank"
            label={Vocabulary.bank}
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            {...register("iban")}
            name="iban"
            id="iban"
            label={Vocabulary.iban}
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <TextField
            {...register(`observations`)}
            name="observations"
            id="observations"
            label={Vocabulary.observations}
            fullWidth
            //multiline
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        {/* ///////////////////////////////////////////////////////////////////////////ALTE INFORMATII */}
        <Grid item xs={12} sm={12} md={12}>
          <SpecialDivider title={Vocabulary.contactPerson} />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            {...register(`contactPerson.name`)}
            name="contactPerson.name"
            id="firstName"
            label={Vocabulary.fullName}
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            {...register(`contactPerson.firstName`)}
            name="contactPerson.firstName"
            id="firstName"
            label={Vocabulary.firstName}
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            {...register(`contactPerson.lastName`)}
            name="contactPerson.lastName"
            id="lastName"
            label={Vocabulary.lastName}
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            {...register(`contactPerson.email`)}
            name="contactPerson.email"
            id="email"
            label={Vocabulary.email}
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            {...register(`contactPerson.phone`)}
            name="contactPerson.phone"
            id="phone"
            label={Vocabulary.phone}
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
    </form>
  );
}
