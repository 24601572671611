import {
  Autocomplete,
  Button,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { Send } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef, useState } from "react";
import { getData } from "../../Services/getData";
import { AxiosResponse } from "axios";
import { endpoints } from "../../Utils/UrlEnum";
import WYSIWYGEditor from "../Messages/WYSIWYGEditor";
import genericStyles from "../../Styles/genericStyles.module.css";
import {
  restoreAllEscapedChars,
  isStringOnlyHTML,
  sanitizeHtml,
} from "../../Utils/Utils";
import { FormsIds } from "../../Utils/Constants";
import Config from "../../Utils/Config";
import { ContactEmailValidationSchema } from "../../Utils/Models";
import { toast } from "react-toastify";

export default function ContactEmail(props: any) {
  const formRef: any = useRef(null);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [resetEditorContent, setResetEditorContent] = useState(false);
  const [shouldRenderError, setShouldRenderError] = useState(false);
  const methods = useForm({
    resolver: yupResolver(ContactEmailValidationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: false,
    criteriaMode: "firstError",
  });

  /**
   *
   */
  useEffect(() => {
    getData(`${endpoints.emailTemplatesEndpoint}`).then(
      (res: AxiosResponse) => {
        if (res && res?.data) {
          setEmailTemplates(res?.data);
        }
      }
    );
  }, []);

  /**
   *
   * @param data
   */
  const handleFormSubmit = (data: any) => {
    //validation
    const renderError = isStringOnlyHTML(
      restoreAllEscapedChars(data.emailContent)
    );
    setShouldRenderError(renderError);
    if (renderError) return;
    //sanitize email and send it to client
    const newData = data.emailTemplate;
    const escapedHtmlContent = sanitizeHtml(
      restoreAllEscapedChars(data.emailContent)
    );
    newData.html = escapedHtmlContent;
    props.sendEmailToClient(newData, () => {
      methods.reset({}, { keepValues: false });
    });
  };

  /**
   *
   * @param error
   */
  const onError = (error: any) => {
    // console.log(error);
    if(!methods.getValues("emailContent")){
      toast.warn(Vocabulary.selectATemplateBeforeSendingAnEmail);
    }

  };

  return (
    <form
      id={FormsIds.contactEmailForm}
      ref={formRef}
      onSubmit={methods.handleSubmit(handleFormSubmit, onError)}
    >
      <Grid container spacing={Config.standardGridSpacing}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Controller
            defaultValue={{}}
            name="emailTemplate"
            control={methods.control}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <Autocomplete
                value={field?.value || {}}
                freeSolo={false}
                disablePortal
                disableClearable
                disabled={props.disabled}
                clearOnBlur={true}
                options={emailTemplates}
                isOptionEqualToValue={(option: any, value: any) =>
                  option?._id === value?._id
                }
                getOptionLabel={(option: any) =>
                  option?.emailTemplateName || ""
                }
                onChange={(event, value: any) => {
                  field.onChange(value);
                  setResetEditorContent(!resetEditorContent);
                  setShouldRenderError(false);
                  methods.setValue("emailContent", value?.html || " ");
                }}
                renderInput={(params) => (
                  <TextField
                    value={field?.value || " "}
                    error={!!error}
                    helperText={error?.message}
                    label={Vocabulary.emailTemplate}
                    name="category"
                    type="search"
                    {...params}
                  />
                )}
              />
            )}
          />
        </Grid>
        {methods.watch("emailContent") && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Controller
              name="emailContent"
              disabled={props.disabled}
              control={methods.control}
              defaultValue={""}
              render={({ field }) => (
                <fieldset className={genericStyles.fieldSet}>
                  <legend className={genericStyles.fieldSetLegend}>
                    {Vocabulary.emailContent}
                  </legend>
                  {shouldRenderError && (
                    <FormHelperText error={shouldRenderError}>
                      {Vocabulary.emailContentError}
                    </FormHelperText>
                  )}
                  <WYSIWYGEditor
                    classOverride={{
                      backgroundColor: "#FFFFFF",
                      overflow: "auto",
                      lineHeight: "normal",
                      padding: 10,
                      height: "200px",
                    }}
                    reset={resetEditorContent}
                    allowNewLine={true}
                    id="emailContent"
                    formRef={formRef}
                    value={methods.watch("emailContent")}
                    onChange={field.onChange}
                    toolbar={[]}
                  />
                </fieldset>
              )}
            />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ justifyContent: "center", display: "flex" }}
        >
          <Button
            type="submit"
            disabled={props.disabled}
            color="blueGrey"
            variant="contained"
            startIcon={<Send />}
          >
            {Vocabulary.sendEmail}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
