/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { endpoints, localUrlEnum } from "../../Utils/UrlEnum";
import styles from "../../Styles/mainHeader.module.css";
import { Fab } from "@mui/material";
import { Email } from "@mui/icons-material";
import { getData } from "../../Services/getData";
import { Vocabulary } from "../../Utils/Vocabulary";
import { WebSocketContext } from "../../Context/WebSocketContext";

export default function NewMessageAnimated() {
  const navigate = useNavigate();
  const [hasNewMessages, setHasNewMessages] = useState<boolean>(false);
  const location = useLocation();
  const websocket = useContext(WebSocketContext);

  const IsDashboardPage = () => {
    const location = useLocation();
    return location.pathname === "/" || location.pathname === "/dashboard";
  };
  /**
   *
   */
  websocket.socket.on(`unreadConversations`, (data: any) => {
    if (data) {
      setHasNewMessages(data?.unreadConversations);
      if (
        data?.unreadConversations &&
        data?.unreadConversations !== hasNewMessages
      ) {
        new Notification(Vocabulary.youHaveUnreadMessages || "");
        // const audio = new Audio("/notification.mp3");
        // if (audio) audio?.play();
      }
    }
  });

  /**
   *
   */
  const checkIfHasNewMessages = () => {
    getData(endpoints.conversation.unreadConversations).then((res) => {
      if (res && res?.data !== null && res?.data !== undefined) {
        setHasNewMessages(res.data);
        if (res.data) {
          new Notification(Vocabulary.youHaveUnreadMessages || "");
          // const audio = new Audio("/notification.mp3");
          // if (audio) audio?.play();
        }
      }
    });
  };

  /**
   *
   */
  useEffect(() => {
    checkIfHasNewMessages();
  }, []);

  /**
   *
   */
  useEffect(() => {
    const path = location.pathname;
    const regexPattern = "\\/messages\\/[a-zA-Z0-9]+$";
    const regex = new RegExp(regexPattern, "i");
    if (regex.test(path)) {
      checkIfHasNewMessages();
    }
  }, [location]);

  /**
   *
   */
  const navigateToMessages = () => {
    navigate(localUrlEnum.messages);
  };

  return (
    <div className={styles.newMessagesContainer}>
      <Fab
        size="small"
        className={`${
          IsDashboardPage()
            ? styles.headerMessageFabDashboard
            : styles.headerMessageFab
        } ${styles.fab}`}
        id="headerMessageFab"
        onClick={navigateToMessages}
      >
        <Email />
      </Fab>
      <div
        className={`${hasNewMessages ? styles.pulse : styles.noUnreadMessages}`}
      >
        +
      </div>
    </div>
  );
}
