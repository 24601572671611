/* eslint-disable no-case-declarations */
import { Add, Delete, MoreVert } from "@mui/icons-material";
import {
  AutocompleteChangeReason,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Popover,
  Typography,
} from "@mui/material";
import { useState } from "react";
import GenericModal from "../GenericModal";
import { Vocabulary } from "../../Utils/Vocabulary";
import StandardActionButtons from "../StandarsActionButtons";
import { EChatModalAction, EWidths } from "../../Utils/Enums";
import ClientForm from "../Clients/ClientForm";
import { ClientModel } from "../../Utils/Models";
import { FormsIds } from "../../Utils/Constants";
import { ChatOptionsProps } from "../../Utils/Types";
import { updateData } from "../../Services/updateData";
import { endpoints } from "../../Utils/UrlEnum";
import { AxiosResponse } from "axios";
import ClientsAutocomplete from "./ClientsAutocomplete";

export default function ChatOptions(props: ChatOptionsProps) {
  const { chat, handleDeleteConversation, updateConversationState } = props;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalAction, setModalAction] = useState<EChatModalAction>(
    EChatModalAction.NoAction
  );
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  /**
   *
   */
  const toggleModalState = () => {
    setOpenModal(!openModal);
    setAnchorEl(null);
    setModalAction(EChatModalAction.NoAction);
  };

  /**
   *
   * @param action
   */
  const handleModalAction = (action: EChatModalAction) => {
    setModalAction(action);
  };

  /**
   *
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   *
   * @param clientId
   */
  const associateConversationWithNewClient = (clientId: string | null) => {
    updateData(
      `${endpoints.conversation.associate}/${chat?._id}/${clientId}`,
      {}
    ).then((res: AxiosResponse) => {
      if (res && res?.data) updateConversationState(res.data);
    });
  };

  /**
   *
   * @param event
   */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement> | null) => {
    setAnchorEl(event?.currentTarget || null);
  };

  const renderButtons = () => {
    switch (modalAction) {
      case EChatModalAction.CreateNewClient:
      case EChatModalAction.AssociateExistingClient:
        return (
          <StandardActionButtons
            okText={Vocabulary.save}
            cancelText={Vocabulary.cancel}
            type="submit"
            formId={FormsIds.clients}
            cancelAction={toggleModalState}
          />
        );
      case EChatModalAction.NoAction:
      default:
        return (
          <StandardActionButtons
            okText={Vocabulary.addClient}
            cancelText={Vocabulary.associateExistingClient}
            okAction={() => {
              handleModalAction(EChatModalAction.CreateNewClient);
            }}
            cancelAction={() => {
              handleModalAction(EChatModalAction.AssociateExistingClient);
            }}
          />
        );
    }
  };

  /**
   *
   * @returns
   */
  const renderContentBasedOnAction = () => {
    switch (modalAction) {
      case EChatModalAction.CreateNewClient:
        //update client with chat data
        const newClient = new ClientModel();
        newClient.name = chat?.potentialClientName;
        newClient.phone = chat?.wa_id ? [chat?.wa_id] : [];
        return (
          <ClientForm
            onSuccess={(
              e: any,
              client: ClientModel,
              reason: AutocompleteChangeReason,
              details?: any
            ) => {
              associateConversationWithNewClient(client?._id);
            }}
            selectedClient={newClient}
            onClose={() => {
              toggleModalState();
            }}
          />
        );
      case EChatModalAction.AssociateExistingClient:
        return (
          <ClientsAutocomplete
            formId={FormsIds.clients}
            submitAction={(data: any) => {
              associateConversationWithNewClient(data.clientId);
            }}
          />
        );
      case EChatModalAction.NoAction:
      default:
        return (
          <Typography>{Vocabulary.addNewClientOrAssociateExisting}</Typography>
        );
    }
  };

  return (
    <>
      <IconButton sx={{ color: "#FFFF" }} onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <List
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              {Vocabulary.conversationOptions}
            </ListSubheader>
          }
        >
          {chat?._id && !chat?.client ? (
            <ListItem onClick={toggleModalState}>
              <ListItemIcon>
                <Add />
              </ListItemIcon>
              <ListItemText
                primary={Vocabulary.associateConversationWithClient}
              />
            </ListItem>
          ) : null}
          <ListItem
            onClick={() => {
              setOpenDeleteModal(true);
            }}
          >
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            <ListItemText primary={Vocabulary.delete} />
          </ListItem>
        </List>
      </Popover>
      <GenericModal
        open={openDeleteModal}
        onClose={() => {
          setOpenDeleteModal(false);
        }}
        title={Vocabulary.confirmDeleteTitle}
        actions={
          <StandardActionButtons
            okAction={() => {
              handleDeleteConversation();
              setOpenDeleteModal(false);
            }}
            cancelAction={() => setOpenDeleteModal(false)}
            okText={Vocabulary.yes}
            cancelText={Vocabulary.no}
          />
        }
      >
        <div>{Vocabulary.confirmDeleteMessage}</div>
      </GenericModal>
      <GenericModal
        maxWidth={EWidths.MD}
        onClose={toggleModalState}
        open={openModal}
        title={Vocabulary.associateClientWithConversation}
        children={renderContentBasedOnAction()}
        actions={renderButtons()}
      />
    </>
  );
}
