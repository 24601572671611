import genericStyles from "../../Styles/genericStyles.module.css";
import MUIDataTableCustomStyle from "../../Components/MUIDataTableCustomStyle";
import { Vocabulary } from "../../Utils/Vocabulary";
import { useState, createContext } from "react";
import { localUrlEnum, endpoints } from "../../Utils/UrlEnum";
import { OrderModel } from "../../Utils/Models";
import { Button, Chip, ToggleButton, Tooltip } from "@mui/material";
import Config from "../../Utils/Config";
import { EComponentTypes, ETableEditAction } from "../../Utils/Enums";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import GenericModal from "../GenericModal";
import { postData } from "../../Services/postData";
import { isMobile, setFontColorBasedOnBackground } from "../../Utils/Utils";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { AxiosResponse } from "axios";
import { TablesIds } from "../../Utils/Constants";
import Filters from "../Widgets/Filters";
import { Filter } from "../../Utils/Types";
import * as yup from "yup";
import useCustomMemo from "../../Hooks/useCustomMemo";

export const CRUDContext = createContext(new OrderModel());

export default function OrdersTable() {
  const [contextValue, setContextValue] = useState(new OrderModel());
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false);
  const [duplicateID, setDuplicateID] = useState();
  const [triggerReload, setTriggerReload]: any = useState();
  const [customTriggerReload, setCustomTriggerReload] = useState<
    boolean | undefined
  >();
  const memo = useCustomMemo();
  const orderStatuses = memo?.storage.orderStatuses || [];
  const navigate = useNavigate();

  const filters: Filter[] = [
    {
      property: "start",
      label: Vocabulary.startDate,
      type: EComponentTypes.DATE_PICKER,
      validation: yup.string().required(Vocabulary.requiredField),
      defaultValue: Config.defaultStartDate,
    },
    {
      property: "end",
      label: Vocabulary.endDate,
      type: EComponentTypes.DATE_PICKER,
      validation: yup.string().required(Vocabulary.requiredField),
      defaultValue: Config.defaultEndDate,
    },
    {
      property: "status",
      label: Vocabulary.orderStatus,
      type: EComponentTypes.AUTOCOMPLETE,
      validation: yup.mixed().nullable(),
      defaultValue: null,
      options: orderStatuses,
      autocompleteFilterProperty: "_id",
    },
  ];

  /**
   *
   */
  const ordersColumns = [
    {
      label: ` `,
      name: "_id",
      options: {
        sort: false,
        display: false,
      },
    },

    {
      label: `${Vocabulary.nameContactPerson}`,
      name: "billing",
      options: {
        sort: false,
        customBodyRender: (rowData: any) => {
          return (
            <>
              <div>
                {`${rowData?.last_name} ${rowData?.first_name} ${
                  !rowData?.last_name && !rowData?.first_name ? "-" : ""
                }
                  `}
              </div>
            </>
          );
        },
      },
    },

    {
      label: `${Vocabulary.company}`,
      name: "billing",
      options: {
        sort: false,
        customBodyRender: (rowData: any) => {
          return (
            <>
              <div>
                {`${rowData?.company}
                  `}
              </div>
            </>
          );
        },
      },
    },
    {
      label: `${Vocabulary.orderNr}`,
      name: "number",
      options: {
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        filter: false,
        sort: false,
        empty: true,
      },
    },
    {
      label: `${Vocabulary.status}`,
      name: "status",
      options: {
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (rowData: any) => {
          return (
            <>
              <Chip
                color="primary"
                key={rowData?._id}
                label={rowData?.name}
                style={{
                  margin: 5,
                  backgroundColor: rowData?.color,
                  color: setFontColorBasedOnBackground(rowData?.color),
                }}
              />
            </>
          );
        },
      },
    },
    {
      label: `${Vocabulary.date}`,
      name: "createdAt",
      options: {
        sort: false,
        customBodyRender: (rowData: any) => {
          return moment(rowData).format(Config.momentEUDateFormat);
        },
      },
    },

    {
      label: `${Vocabulary.total}`,
      name: "total",
      options: {
        sort: false,
      },
    },
  ];

  /**
   *
   * @param rowIndex
   * @param value
   * @returns
   */
  const renderCustomColumns = (rowIndex: number, value: any) => {
    return !value.wordPress_Id || value.wordPress_Id !== "" ? (
      <Tooltip title={Vocabulary.duplicate}>
        <ToggleButton
          onClick={(e: any) => {
            e.stopPropagation();
            setOpenDuplicateModal(true);
            setDuplicateID(value._id);
          }}
          value="center"
          aria-label="centered"
        >
          <ContentCopyIcon />
        </ToggleButton>
      </Tooltip>
    ) : null;
  };

  /**
   *
   * @param data
   */
  const duplicateOrder = () => {
    const url = `${endpoints.duplicateOrder}${duplicateID}`;
    postData(url, " ").then((res: AxiosResponse) => {
      if (res?.data) {
        setOpenDuplicateModal(!openDuplicateModal);
        setTriggerReload(triggerReload ? false : true);
      }
    });
  };

  /**
   *
   * @param data
   */
  const syncWordPressData = () => {
    setCustomTriggerReload(
      customTriggerReload !== undefined ? !customTriggerReload : false
    );
  };

  return (
    <div className={genericStyles.mainContainer}>
      <Filters filters={filters} />
      <CRUDContext.Provider value={contextValue}>
        <MUIDataTableCustomStyle
          buttons={
            <Button
              onClick={() => syncWordPressData()}
              style={{ marginLeft: 10 }}
              variant="contained"
              startIcon={<CloudSyncIcon />}
            >
              {!isMobile() ? Vocabulary.wordpressSync : null}
            </Button>
          }
          onEditAction={ETableEditAction.Redirect}
          title={Vocabulary.orders}
          columns={ordersColumns}
          renderCustomColumns={renderCustomColumns}
          CRUDEndPoint={endpoints.ordersEndpoint}
          id={TablesIds.orders}
          setContextValue={setContextValue}
          rowClickURL={`${localUrlEnum.order}/`}
          onRowClick={(rowData: any) => {
            setContextValue(rowData);
            navigate(`${localUrlEnum.order}/${rowData._id}`);
          }}
          triggerReload={triggerReload}
          customGetDataTable={endpoints.syncWordPressDataOrders}
          customTriggerReload={customTriggerReload}
          selectableRows={true}
        />
      </CRUDContext.Provider>
      <GenericModal
        open={openDuplicateModal}
        onClose={() => {
          setOpenDuplicateModal(false);
        }}
        title={Vocabulary.confirm}
        actions={
          <div className={genericStyles.modalActions}>
            <Button
              color="secondary"
              onClick={() => {
                setOpenDuplicateModal(false);
              }}
              variant="outlined"
              fullWidth
            >
              {Vocabulary.cancel}
            </Button>
            <Button
              fullWidth
              variant="contained"
              onClick={() => duplicateOrder()}
            >
              {Vocabulary.duplicate}
            </Button>
          </div>
        }
      >
        {Vocabulary.confirmDuplicateMessage}
      </GenericModal>
    </div>
  );
}
