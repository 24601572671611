/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Vocabulary } from "../../Utils/Vocabulary";
import { TodaysContactsState } from "../../Utils/Models";
import { useContext, useEffect, useState } from "react";
import { getData } from "../../Services/getData";
import { endpoints } from "../../Utils/UrlEnum";
import { Label, TodaysContactsResponse } from "../../Utils/Types";
import DynamicTabs from "../DynamicTabs";
import TabPanel from "../TabPanel";
import styles from "../../Styles/dashboardContacts.module.css";
import genericStyles from "../../Styles/genericStyles.module.css";
import { useSearchParams } from "react-router-dom";
import { WebSocketContext } from "../../Context/WebSocketContext";
import ContactModal from "./ContactModal";
import useQueryStringParser from "../../Hooks/useQueryStringParser";
import ContactsTable from "./ContactsTable";
import { ComponentsNames } from "../../Utils/Constants";
import ContactsList from "./ContactsList";
import useCustomMemo from "../../Hooks/useCustomMemo";

export default function TodaysContacts() {
  const [state, setState] = useState<TodaysContactsState>(
    new TodaysContactsState()
  );
  const [tabValue, setTabValue] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const websocket = useContext(WebSocketContext);
  const memo = useCustomMemo();
  const contactSettings = memo?.storage?.contactSettings;
  const contactId = searchParams.get("id");
  const [open, setOpen] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [labels, setLabels] = useState<Label[]>([]);

  /**
   *
   */
  useEffect(() => {
    const tab = searchParams.get("tab");
    setTabValue(tab ? parseInt(tab) : 0);
  }, [searchParams]);

  /**
   *
   */
  useQueryStringParser((data: any) => {
    if (data) getTodaysContacts(data.search);
  });

  /**
   *
   */
  useEffect(() => {
    if (shouldUpdate) {
      getTodaysContacts();
      setShouldUpdate(false);
    }
  }, [shouldUpdate]);

  /**
   *
   */
  useEffect(() => {
    renderOldContacts();
  }, [contactSettings, state]);

  /**
   *
   */
  websocket.socket.on(`dashboardSummaryContacts`, (data: any) => {
    if (data && data.summary) {
      setState({
        ...state,
        noAvailableContacts: data.summary.noAvailableContacts,
        noTakenContacts: data.summary.noTakenContacts,
        noTotalResults: data.summary.noTotalResults,
        noDelayedContacts: data.summary.noDelayedContacts,
      });
    }
  });

  /**
   *
   */
  const getTodaysContacts = (search?: string) => {
    getData(
      `${endpoints.getDashboardSummaryContacts}/${search ? search : ""}`
    ).then((res: TodaysContactsResponse) => {
      if (res && res?.data)
        setState({
          ...state,
          noAvailableContacts: res.data?.noAvailableContacts,
          noTakenContacts: res.data?.noTakenContacts,
          noTotalResults: res.data?.noTotalResults,
          noDelayedContacts: res.data?.noDelayedContacts,
        });
    });
  };

  /**
   *
   */
  const renderOldContacts = () => {
    //create copy of labels
    const newLabels: any = [
      {
        label: Vocabulary.availableClients,
        badge: state.noAvailableContacts,
      },
      {
        label: Vocabulary.takenClients,
        badge: state.noTakenContacts,
      },
    ];
    if (contactSettings?.value?.shouldRenderOldContacts) {
      //check if the label already exists
      const index = newLabels.findIndex(
        (label: any) => label.label === Vocabulary.delayedClients
      );
      if (index === -1) {
        newLabels.push({
          label: Vocabulary.delayedClients,
          badge: state.noDelayedContacts,
          tabColor: "#FF5733",
        });
      } else {
        newLabels[index].badge = state.noDelayedContacts;
      }
    }
    setLabels(newLabels);
  };

  return (
    <div className={genericStyles.fullWidthElement}>
      <DynamicTabs
        tabValue={tabValue}
        handleChange={(tabIndex: number) => setTabValue(tabIndex)}
        badge={state.noTotalResults || "0"}
        labels={labels}
      />
      <TabPanel
        value={tabValue}
        index={0}
        className={styles.dashboardContactsTabPanel}
      >
        <ContactsList
          websocketEventName={`availableContacts`}
          shouldUpdate={shouldUpdate}
          setShouldUpdate={setShouldUpdate}
          url={endpoints.getAvailableContacts}
        />
      </TabPanel>
      <TabPanel
        value={tabValue}
        index={1}
        className={styles.dashboardContactsTabPanel}
      >
        <ContactsList
          websocketEventName={`takenContacts`}
          shouldUpdate={shouldUpdate}
          setShouldUpdate={setShouldUpdate}
          url={endpoints.getTakenContacts}
        />
      </TabPanel>
      {contactSettings?.value?.shouldRenderOldContacts ? (
        <TabPanel
          value={tabValue}
          index={2}
          className={styles.dashboardContactsTableTabPanel}
        >
          <ContactsTable name={ComponentsNames?.ContactsTable} />
        </TabPanel>
      ) : null}
      <ContactModal
        open={open}
        setOpen={setOpen}
        setShouldUpdate={setShouldUpdate}
        contactId={contactId}
      />
    </div>
  );
}
